import { GUI } from "dat.gui";
import React, { useEffect, useRef, useState } from "react";
import { useLayoutEffect } from "react";
import * as THREE from "three";
import { DirectionalLight } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const OrbVironment = () => {
  const canvas = useRef(null);
  const [loading, setloading] = useState(true);
  useLayoutEffect(() => {
    if (window.innerWidth < 700) {
      alert("Use on desktop screen for better experience");
    }
  });
  useEffect(() => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xbfe3dd);
    const light = new THREE.AmbientLight(0xffffff, 1);
    light.castShadow = true;
    scene.add(light);

    const camera = new THREE.PerspectiveCamera(
      40,
      window.innerWidth / window.innerHeight,
      1,
      2000
    );
    camera.position.set(-6.9, 12.7, -18.8);

    const renderer = new THREE.WebGLRenderer({
      canvas: canvas.current,
      antialias: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    const controls = new OrbitControls(camera, canvas.current);
    controls.enableDamping = true;

    const loader = new GLTFLoader();
    let model;
    loader.load(
      "./assets/ftm/scene.gltf",
      (gltf) => {
        model = gltf.scene;
        scene.add(model);
        if (window.innerWidth < 800) {
          model.scale.set(0.7, 0.7, 0.7);
        }
      },
      () => console.log("Loaded"),
      (err) => {
        console.log(err);
        console.log("An error occurred while loading");
      }
    );
    const gui = new GUI();
    gui.add(camera.position, "x", -50, 50).name("X AXIS");
    gui.add(camera.position, "y", -50, 50).name("Y AXIS");
    gui.add(camera.position, "z", -50, 50).name("Z AXIS");

    function animate() {
      requestAnimationFrame(animate);

      controls.update();

      renderer.render(scene, camera);
    }
    animate();
  });

  return <canvas ref={canvas}></canvas>;
};

export default OrbVironment;
